<template>
  <div class="controls">
    <div class="inputs">
      <AtomInput
        name="От"
        symbol="coin"
        v-model="modelFrom"
        @change="onChange('rate_from')"
        :process-field="processField"
      />
      <AtomInput
        name="До"
        symbol="coin"
        v-model="modelTo"
        @change="onChange('rate_to')"
        :process-field="processField"
      />
    </div>
    <SliderInput class="range-slider" @update="onRangeUpdate" ref="slider" @submit="emit('submit')" />
  </div>
</template>

<script setup>
const props = defineProps(['from', 'to', 'min', 'max'])
const emit = defineEmits(['update:from', 'update:to', 'change', 'submit'])

const modelFrom = computed({
  get: () => props.from,
  set: value => emit('update:from', value)
})
const modelTo = computed({
  get: () => props.to,
  set: value => emit('update:to', value)
})

const slider = ref()

const processField = value => value.toString().replace(/\D/g, '')

const percentToRate = percent => Math.round(props.min + (percent * (props.max - props.min)))
const onRangeUpdate = (value, index) => {
  (index === 0 ? modelFrom : modelTo).value = percentToRate(value[index])
}

const rateToPercent = rate => (rate - props.min) / (props.max - props.min)
const onChange = name => {
  emit('change', name)
  sliderUpdate()
}
const sliderUpdate = () => slider.value.updateValue([modelFrom, modelTo].map((m, i) => rateToPercent(m.value ? m.value : (i ? props.max : props.min))))

const { width } = useWindowSize()
onMounted(() => sliderUpdate())
watch([width, modelFrom, modelTo], sliderUpdate)

defineExpose({ sliderUpdate })
</script>

<style scoped lang="scss">

</style>
