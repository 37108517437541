<template>
  <FilterChips
    search
    name="Направления"
    not-found-text="Направления не найдены"
    expand-text="Развернуть все направления"
    shrink-text="Свернуть все направления"
    :items="items"
  />
</template>

<script setup>
import { getSkills } from '~/api/skills'

const props = defineProps({
  items: Array
})

const { data: items } = await useAsyncData(async () => props.items ?? (await getSkills()).data, { watch: [() => props.items] })
</script>
