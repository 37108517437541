<template>
  <div class="slider-box" ref="box">
    <div class="slider-line">
      <div class="slider-selected" ref="selection"></div>
    </div>
    <div class="slider-points">
      <div class="slider-point" data-id="start" ref="pointStart" @mousedown="grab(pointStart)" @touchstart="grab(pointStart, $event)"></div>
      <div class="slider-point" data-id="end" ref="pointEnd" @mousedown="grab(pointEnd)" @touchstart="grab(pointEnd, $event)"></div>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(['update', 'submit'])

const value = ref([0, 1])

const updateValue = (newValue = null) => {
  const slider = box.value
  const start = pointStart.value
  const end = pointEnd.value

  const length = slider.clientWidth - start.clientWidth * 2 - 1

  value.value = newValue === null ? [
    start.offsetLeft / length,
    (end.offsetLeft - end.clientWidth) / length
  ] : newValue
  if (newValue === null) emit('update', value.value, grabbingEl.value.getAttribute('data-id') === 'start' ? 0 : 1)

  start.style.left = (length * value.value[0]) + 'px'
  end.style.left = (length * value.value[1] + end.clientWidth) + 'px'

  selection.value.style.left = (length * value.value[0]) + 'px'
  selection.value.style.width = ((value.value[1] - value.value[0]) * length + start.clientWidth) + 'px'
}
defineExpose({ updateValue })

const box = ref(null)
const selection = ref(null)
const pointStart = ref(null)
const pointEnd = ref(null)

const grabPoint = ref(0)
const grabbingEl = ref(false)

const mouseX = ref(0)

const onMove = e => {
  mouseX.value = e.touches ? e.touches[0].clientX : e.x
  calculateNearest()

  if (grabbingEl.value === false) return

  const boxCoords = box.value.getBoundingClientRect()

  let min, max
  if (grabbingEl.value.getAttribute('data-id') === 'start') {
    min = 0
    max = pointEnd.value.offsetLeft - grabbingEl.value.clientWidth
  } else {
    min = pointStart.value.offsetLeft + pointStart.value.clientWidth
    max = boxCoords.width - grabbingEl.value.getBoundingClientRect().width - 1
  }

  grabbingEl.value.style.left = Math.max(
    min,
    Math.min(
      max,
      (mouseX.value - (boxCoords.x + grabPoint.value)) // value
    )
  ) + 'px'

  updateValue()
}
const grab = (el, event = null) => {
  if (event?.touches) mouseX.value = event.touches[0].clientX
  grabbingEl.value = el
  grabPoint.value = mouseX.value - grabbingEl.value.getBoundingClientRect().x
}
const release = () => {
  if (grabbingEl.value !== false) emit('submit')
  grabbingEl.value = false
}

onMounted(() => {
  document.addEventListener('mousemove', onMove)
  document.addEventListener('mouseup', release)
  document.addEventListener('touchmove', onMove)
  document.addEventListener('touchend', release)
})

onBeforeUnmount(() => {
  document.removeEventListener('mousemove', onMove)
  document.removeEventListener('mouseup', release)
  document.removeEventListener('touchmove', onMove)
  document.removeEventListener('touchend', release)
})

const calculateNearest = () => {
  const start = pointStart.value.getBoundingClientRect()
  const end = pointEnd.value.getBoundingClientRect()

  const isStartCloser = Math.abs(mouseX.value - (start.x + start.width / 2)) <= Math.abs(mouseX.value - (end.x + end.width / 2))
  pointStart.value.style.zIndex = isStartCloser ? 2 : 1
  pointEnd.value.style.zIndex = isStartCloser ? 1 : 2
}
</script>

<style scoped lang="scss">
.slider-box {
  height: 20px;
  position: relative;
  user-select: none;
  overflow: hidden;
}

.slider-line {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3px;
  width: calc(100% - 20px);
  border-radius: 1.5px;
  transform: translate(-50%, -50%);
  background: var(--color-elements-quantery);

  & .slider-selected {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: inherit;
    background: var(--color-primary);
  }
}

.slider-point {
  height: 100%;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.08);
  background: var(--color-primary);
  position: absolute;
  top: 0;
  cursor: pointer;
  &:last-child {
    left: calc(100% - 20px);
  }

  &::after {
    content: '';
    position: absolute;
    width: 250%;
    height: 250%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
