<template>
  <PopperMenu
    name="Сортировка"
    select
    :options="options"
    v-model="model"
    offset-distance="10"
    placement="bottom-end"
  >
    <Chip v-if="chip" class="chip" icon>
      {{ selected.name }}
      <IconSort class="icon icon--main" />
    </Chip>
    <button v-else class="sort-control">
      <IconSort class="icon icon--main" />
      <span>{{ selected.id !== 'default' ? 'Сначала: ' : '' }}{{ selected.name }}</span>
      <IconChevronDown class="icon" />
    </button>
    <template #footer="{ submit }">
      <BaseButton color-primary small stretch @click="submit">Показать</BaseButton>
    </template>
  </PopperMenu>
</template>

<script setup>
import { IconChevronDown } from '@tabler/icons-vue'

const props = defineProps({
  chip: Boolean,
  options: Array,
  modelValue: [String, Number]
})
const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
const selected = computed(() => props.options.find(o => o.id === model.value))
</script>

<style scoped lang="scss">
.sort-control {
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-elements-primary);
  .icon {
    width: 17px;
    height: 17px;
    flex-shrink: 0;
    &--main {
      width: 24px;
      height: 24px;
    }
  }
}
.icon--main {
  color: var(--color-primary) !important;
}
</style>
